const InventoryItems = {
  formFields() {
    return [
      {key: 'id', label: '商品ID'},
      {key: 'item_code', label: '品番'},
      {key: 'name', label: '商品名'},
      {key: 'size', label: 'サイズ'},
      {key: 'color', label: '色'},
      {key: 'cost', label: '仕入原価'},
      {key: 'price', label: '販売価格'},
      {key: 'retail_price', label: 'プロパー上代'},
      {key: 'delete', label: '削除'},
    ]
  },
  detailFields() {
    return [
      {key: 'id', label: '商品ID'},
      {key: 'item_code', label: '品番'},
      {key: 'name', label: '商品名'},
      {key: 'size', label: 'サイズ'},
      {key: 'color', label: '色'},
      {key: 'cost', label: '仕入原価'},
      {key: 'price', label: '販売価格'},
      {key: 'retail_price', label: 'プロパー上代'},
      {key: 'status', label: 'ステータス'},
    ]
  }
}
export default InventoryItems