<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <strong>棚卸し商品詳細</strong>
        </CCardHeader>
        <CCardBody>
          <div v-if="loading">
            <LoadingCompoment />
          </div>
          <div v-else :key="key">
            <CRow class="text-right mb-2">
              <CCol sm="12">
                <CButton color="primary" class="mr-2" @click="handleCsvDownload">CSV ダウンロード</CButton>
                <CButton
                  v-if="userShopId === WAREHOUSUE && workStatus === NOT_DONE"
                  color="primary"
                  class="mr-2"
                  @click="handleCarryOutInventoryWork">
                    棚卸しを実施
                </CButton>
              </CCol>
            </CRow>
            <DataTable
              hover
              striped
              border
              :items="items"
              :fields="fields"
            >
              <td slot="cost" slot-scope="{item}" class="text-right">
                <div>{{ item.cost }}</div>
              </td>
              <td slot="price" slot-scope="{item}" class="text-right">
                <div>{{ item.price }}</div>
              </td>
              <td slot="retail_price" slot-scope="{item}" class="text-right">
                <div>{{ item.retail_price }}</div>
              </td>
            </DataTable>
        </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import InventoryItems from "@/views/inventory-works/InventoryItemFields";
import DataTable from "@/components/DataTable";
import {numberWithCommas} from "@/utils/utils";
import inventoryWorkApiOther from '../../api/inventoryWorkApi';
import {STATUS_INVENTORY_ITEM, WAREHOUSUE} from '@/utils/constance';
import Vue from "vue";

export default {
  name: 'InventoryWorkDetail',
  components: {DataTable},
  data() {
    return {
      loading: false,
      inventoryWorkId: this.$route.params.id,
      items: [],
      fields: InventoryItems.detailFields(),
      key: Math.random(),
      WAREHOUSUE: WAREHOUSUE,
      userShopId: this.$store.getters.user?.shop_id,
      workStatus: 0,
      NOT_DONE: 0,
    }
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;
      if (!this.inventoryWorkId) {
        return;
      }

      inventoryWorkApiOther
        .getItemStatuses({id : this.inventoryWorkId})
        .then(response => {
          this.handleResponseData(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleResponseData(data) {
      this.workStatus = data.status;
      for (const invItemStatus of data.inventory_item_status) {
        const itemMaster = invItemStatus.item?.item_master;
        const itemData = {
          id: invItemStatus.item?.id ?? "",
          item_code: itemMaster?.item_code ?? "",
          name: itemMaster?.name ?? "",
          size: itemMaster?.size.name ?? "",
          color: itemMaster ? itemMaster.color.color_code + ':' + itemMaster.color.name : "",
          cost: numberWithCommas(itemMaster?.cost ?? 0),
          price: invItemStatus.item?.stock.price ? numberWithCommas(invItemStatus.item?.stock.price ?? 0) : numberWithCommas(itemMaster?.price ?? 0),
          retail_price: numberWithCommas(itemMaster?.retail_price ?? 0),
          status: STATUS_INVENTORY_ITEM[invItemStatus.status ?? 0]
        }
        this.items.push(itemData);
      }
    },

    handleCsvDownload() {
      this.loading = true;
      const params = {id: this.inventoryWorkId};

      inventoryWorkApiOther.csvDownload(params).then(response => {
        Vue.$toast.success('ダウンロード成功');
        this.csvDownload(response.data);
      }).catch(() => {
        Vue.$toast.error('ダウンロードに失敗しました')
      }).finally(() => {
        this.loading = false
      });
    },

    csvDownload(data) {
      const fileURL = window.URL.createObjectURL(new Blob([data]));
      const fileLink = document.createElement('a');
      const timeCurrent = new Date();

      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'inventory-work-' + timeCurrent.toLocaleString() + '.csv');
      document.body.appendChild(fileLink);
      fileLink.click();
    },

    handleCarryOutInventoryWork() {
      this.carryOutInventoryWork();
    },

    carryOutInventoryWork() {
      this.loading = true;
      inventoryWorkApiOther.carryOutInventoryWork({id: this.inventoryWorkId}).then(response => {
        if (response.data === 'success') {
          Vue.$toast.success("Successfully carried out inventory work");
        } else {
          Vue.$toast.error("There was a discrepancy on the data. Please try again");
          this.items = [];
          this.getData();
        }
      }).catch(() => {
        Vue.$toast.error('Change Error!');
      }).finally(() => {
        this.loading = false;
      });
    },
  }
}
</script>
